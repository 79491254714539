import Select from "react-select";

const AutoSelect = ({ items, setSelectedDevice }) => {
	const devices = items.map((item) => ({ value: item, label: item }));

	return (
		<Select
			className="basic-single"
			classNamePrefix="select"
			isClearable
			isSearchable
			options={devices}
			placeholder="Enter your device model"
			onChange={(value) => setSelectedDevice(value?.value || "")}
		/>
	);
};

export default AutoSelect;

function ThumbDown({ color }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="thumbs-down">
        <g clipPath="url(#clip0_161_178)">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7094 2.54377C13.547 2.40438 13.3391 2.32947 13.1251 2.33326L13.1133 2.33336H12V8.33336H13.1133L13.1251 8.33347C13.3391 8.33725 13.547 8.26234 13.7094 8.12295C13.862 7.99196 13.9644 7.8126 14 7.61545V3.05127C13.9644 2.85412 13.862 2.67476 13.7094 2.54377ZM10.6667 8.85855L8.26145 14.2703C8.06052 14.2062 7.87575 14.0947 7.72386 13.9428C7.47381 13.6928 7.33333 13.3537 7.33333 13V10.3334C7.33333 9.96517 7.03486 9.66669 6.66667 9.66669H2.89333L2.88578 9.66674C2.78915 9.66783 2.69343 9.6479 2.60526 9.60833C2.51709 9.56876 2.43858 9.51049 2.37516 9.43757C2.31175 9.36464 2.26495 9.2788 2.238 9.18599C2.2111 9.0933 2.20463 8.99588 2.21906 8.90045C2.21904 8.90058 2.21908 8.90032 2.21906 8.90045L3.13913 2.89998C3.16324 2.74102 3.24399 2.59612 3.36649 2.49198C3.489 2.38785 3.64502 2.3315 3.8058 2.33332L10.6667 2.33336V8.85855ZM13.108 1.00003C13.6463 0.992072 14.1691 1.18125 14.5778 1.53204C14.9881 1.88423 15.2548 2.37482 15.3273 2.91067C15.3313 2.94028 15.3333 2.97014 15.3333 3.00003V7.66669C15.3333 7.69658 15.3313 7.72644 15.3273 7.75606C15.2548 8.2919 14.9881 8.78249 14.5778 9.13468C14.1691 9.48548 13.6463 9.67465 13.108 9.66669H11.7666L9.27587 15.2708C9.16887 15.5115 8.93013 15.6667 8.66667 15.6667C7.95942 15.6667 7.28115 15.3857 6.78105 14.8856C6.28095 14.3855 6 13.7073 6 13V11H2.89674C2.60824 11.0027 2.32256 10.9429 2.05931 10.8248C1.7948 10.7061 1.55927 10.5313 1.36902 10.3125C1.17878 10.0937 1.03838 9.83616 0.957543 9.55773C0.876708 9.2793 0.857375 8.98663 0.900882 8.69998L1.82087 2.70007C1.82084 2.70027 1.8209 2.69987 1.82087 2.70007C1.8933 2.2234 2.13559 1.78833 2.50296 1.47606C2.86944 1.16456 3.33586 0.995588 3.81672 1.00003H13.108Z"
            fill={color || "#5A55AB"}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_161_178">
          <rect y="0.333374" width="16" height="16" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ThumbDown;

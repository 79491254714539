const StepsComponent = ({ inputText, language }) => {
	// Split the text into lines
	const lines = inputText.split("\n");
	// Function to format a line
	function formatLine(line, index, array) {
		// Check for bold and italic text
		const boldItalicRegex = /(\*\*|__)(.*?)\1/g;
		const italicRegex = /(\*|_)(.*?)\1/g;
		line = line.replace(boldItalicRegex, "<b>$2</b>");
		line = line.replace(italicRegex, "<i>$2</i>");
		if (
			(line.startsWith("Warnung: Es") ||
				line.startsWith("Kontaktiere") ||
				line.startsWith(" https://1komma5grad.com/de/ueber-uns/kontakt")) &&
			language !== "German"
		)
			return;
		const listItemRegex =
			/^((Step|Schritt|Paso|Krok|Étape) )?(\d+)\.? *:?\s*(.*)/i;
		const match = line.match(listItemRegex);
		if (match) {
			const stepNumber = match[3]; // extract the step number
			const stepText = match[4]; // extract the step text
			const isFirstChild = index === 0;
			return (
				<div
					key={index}
					className={isFirstChild ? "step first" : "step"}
					// style={{
					// 	paddingTop: isFirstChild ? "10px" : "0",
					// 	paddingBottom: "10px",
					// }}
				>
					<div
						style={{
							display: "inline-block",
							width: "24px",
							height: "24px",
							borderRadius: "50%",
							backgroundColor: "#E3E9F2",
							color: "#000",
							textAlign: "center",
							lineHeight: "26px",
							marginRight: "5px",
							fontSize: "12px",
							fontWeight: "700",
						}}
					>
						{stepNumber}
					</div>
					<span dangerouslySetInnerHTML={{ __html: stepText }} />
				</div>
			); // format as step with number and padding
		} else if (
			line.startsWith("Warning:") ||
			line.startsWith("Warnung:") ||
			line.startsWith("Achtung:")
		) {
			return (
				<p key={index}>
					<span role="img" aria-label="warning" style={{ marginRight: "5px" }}>
						⚠️
					</span>{" "}
					{line}
				</p>
			); // format as warning text
		}
		return (
			<p key={index} className="steps-line">
				{line}
			</p>
		); // format as regular text
	}

	// Format each line
	const formattedLines = lines.map(formatLine);

	return <div>{formattedLines}</div>;
};

export default StepsComponent;
import { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import Navbar from "./components/Navbar";
import FormContainer from "./components/FormContainer";
import Loading from "./components/Loading";

const defaultVars = {
	name: "Innential",
	mainColor: "#8745EF",
	logo: "https://via.placeholder.com/150",
	slug: "Innential",
	mainLanguage: "English",
	exampleQuestions: [],
	pdfFiles: ["Inverter", "Battery", "Solar Panel"],
	type: "Manufacturer",
};

function App() {
  const [variables, setVariables] = useState(defaultVars);
	const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
	setIsLoading(true);
	const instanceId = process.env.REACT_APP_INSTANCE_ID;
	const environment = process.env.REACT_APP_ENV;

	// Check if instanceId is not available
	if (!instanceId) {
		setVariables(defaultVars);
		setIsLoading(false);
		return;
	}

	let api;
	switch (environment) {
		case "development":
			api = "http://localhost:9000/instance";
			break;
		case "staging":
			api = "https://admin-api.service-assistant-staging.com/instance";
			break;
		case "production":
			api = "https://admin-api.service-assistant.com/instance";
			break;
		default:
			console.warn(`Unsupported environment: ${environment}`);
			setIsLoading(false); // Set loading state to false if environment is unsupported
			return; // Exit useEffect early
	}

	// Ensure api is defined before proceeding
	if (!api) {
		console.error("API URL could not be determined.");
		setIsLoading(false);
		return;
	}

	const sendData = {
		instanceId: instanceId,
	};

	axios
		.get(api, {
			params: sendData,
		})
		.then((response) => {
			setVariables(response.data);
			     const pageLinks = document.getElementsByTagName("link");
						const pageLink = Array.from(pageLinks).find((link) => {
							return link.rel === "icon";
						});
						if (pageLink && response?.data?.favIcon) {
							pageLink.href = response.data.favIcon;
						}
			setIsLoading(false);
		})
		.catch((error) => {
			console.error("Error:", error);
			setIsLoading(false);
		});
}, []);

if (isLoading) {
    return <Loading />;
  }
	return (
		<div className="app">
			<Navbar logo={variables.logo} />
			<div className="main-container">
				<FormContainer variables={variables} />
			</div>
		</div>
	);
}

export default App;

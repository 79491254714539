import axios from "axios";

const webhookURL = `https://hooks.zapier.com/hooks/catch/3484805/3exg7v2/`;
const webhookURL1 = `https://hooks.zapier.com/hooks/catch/3484805/3eq71jh/`;
const webhookURL2 = `https://hooks.zapier.com/hooks/catch/3484805/3exxo67/`;

export const handleSendResult = async (bool, data, userInput, company) => {
	try {
		let fileName;
		let fileTitle;
		if (Array.isArray(data.exact_file)) {
			const fileObj = data?.exact_file && data?.exact_file[0]; // Get the first object from the array
			fileName = fileObj && Object.keys(fileObj)[0]; // Get the key of the object which is the file name
			fileTitle = fileObj && fileObj[fileName];
		} else {
			fileName = data.exact_file;
			fileTitle = data.exact_file;
		}

		// Format the similar files
		const similarFiles = data?.similar_files
			?.map((file) => {
				if (typeof file === "string") {
					// If it's a string, use it directly
					return file;
				} else {
					// If it's an object, extract the name and title
					const name = Object.keys(file)[0];
					const title = file[name];
					return `${name}: ${title}`;
				}
			})
			.join(",\n ");

		await axios.post(
			webhookURL,
			JSON.stringify({
				company: company,
				device: userInput.device,
				problem: userInput.problem,
				exact_file: !bool ? "" : fileName ? `${fileName}: ${fileTitle}` : "",
				similar_files: !bool ? "" : similarFiles ? similarFiles : "",
				gpt_response: !bool ? "" : data?.gpt_response ? data?.gpt_response : "",
				date: new Date(),
				language: userInput.language,
			})
		);
	} catch (err) {
		console.error(err);
	}
};
export const handleFeedbackSubmit = async (thumb, data, userInput, setFeedbackAdded, company) => {
	try {
		let fileName;
		let fileTitle;
		if (Array.isArray(data.exact_file)) {
			const fileObj = data?.exact_file && data?.exact_file[0]; // Get the first object from the array
			fileName = fileObj && Object.keys(fileObj)[0]; // Get the key of the object which is the file name
			fileTitle = fileObj && fileObj[fileName];
		} else {
			fileName = data.exact_file;
			fileTitle = data.exact_file;
		}
		// Format the similar files
		const similarFiles = data?.similar_files
			?.map((file) => {
				if (typeof file === "string") {
					// If it's a string, use it directly
					return file;
				} else {
					// If it's an object, extract the name and title
					const name = Object.keys(file)[0];
					const title = file[name];
					return `${name}: ${title}`;
				}
			})
			.join(",\n ");

		await axios.post(
			webhookURL1,
			JSON.stringify({
				company: company,
				device: userInput.device,
				problem: userInput.problem,
				exact_file: `${fileName}: ${fileTitle}` || "",
				similar_files: similarFiles || "",
				gpt_response: data?.gpt_response || "",
				date: new Date(),
				language: userInput.language,
				vote: thumb,
			})
		);
		setFeedbackAdded(true);
	} catch (err) {
		console.error(err);
	}
};
export const handleSendRelatedDeviceClick = async (device, data, userInput, company) => {
	try {
		let fileName;
		let fileTitle;
		if (Array.isArray(data.exact_file)) {
			const fileObj = data?.exact_file && data?.exact_file[0]; // Get the first object from the array
			fileName = fileObj && Object.keys(fileObj)[0]; // Get the key of the object which is the file name
			fileTitle = fileObj && fileObj[fileName];
		} else {
			fileName = data.exact_file;
			fileTitle = data.exact_file;
		}
		// Format the similar files
		const similarFiles = data?.similar_files
			?.map((file) => {
				if (typeof file === "string") {
					// If it's a string, use it directly
					return file;
				} else {
					// If it's an object, extract the name and title
					const name = Object.keys(file)[0];
					const title = file[name];
					return `${name}: ${title}`;
				}
			})
			.join(",\n ");

		await axios.post(
			webhookURL2,
			JSON.stringify({
				company: company,
				device: userInput.device,
				problem: userInput.problem,
				exact_file: `${fileName}: ${fileTitle}` || "",
				similar_files: similarFiles || "",
				gpt_response: data?.gpt_response || "",
				date: new Date(),
				language: userInput.language,
				clicked_device: device,
			})
		);
	} catch (err) {
		console.error(err);
	}
};
